:root {
	/* Color */
	--font-000: rgb(0 0 0 / 100%);
	--font-333: rgb(51 51 51 / 100%);
	--font-555: rgb(85 85 85 / 100%);
	--font-777: rgb(119 119 119 / 100%);
	--font-999: rgb(153 153 153 / 100%);
	--font-ccc: rgb(204 204 204 / 100%);
	--font-eee: rgb(238 238 238 / 100%);

	--font-vip: rgb(0 0 0 / 100%);
	--font-100: rgb(0 0 0 / 100%);
	--font-default: rgb(0 0 0 / 85%);
	--font-85: rgb(0 0 0 / 85%);
	--font-secondary: rgb(0 0 0 / 75%);
	--font-75: rgb(0 0 0 / 75%);
	--font-65: rgb(0 0 0 / 65%);
	--font-60: rgb(0 0 0 / 60%);
	--font-55: rgb(0 0 0 / 55%);
	--font-45: rgb(0 0 0 / 45%);
	--font-35: rgb(0 0 0 / 35%);
	--font-25: rgb(0 0 0 / 25%);
	--font-15: rgb(0 0 0 / 15%);
	--font-5: rgb(0 0 0 / 5%);
	--font-0: rgb(0 0 0 / 0%);

	--black-100: rgb(0 0 0 / 100%);
	--black-85: rgb(0 0 0 / 85%);
	--black-75: rgb(0 0 0 / 75%);
	--black-65: rgb(0 0 0 / 65%);
	--black-55: rgb(0 0 0 / 55%);
	--black-45: rgb(0 0 0 / 45%);
	--black-35: rgb(0 0 0 / 35%);
	--black-25: rgb(0 0 0 / 25%);
	--black-15: rgb(0 0 0 / 15%);
	--black-10: rgb(0 0 0 / 10%);
	--black-885: rgb(0 0 0 / 8.85%);
	--black-585: rgb(0 0 0 / 5.85%);
	--black-5: rgb(0 0 0 / 5%);
	--black-0: rgb(0 0 0 / 0%);
	--black-light-485: rgb(0 0 0 / 4.85%);
	--black-light-385: rgb(0 0 0 / 3.85%);
	--black-light-285: rgb(0 0 0 / 2.85%);
	--black-light-185: rgb(0 0 0 / 1.85%);
	--black-light-085: rgb(0 0 0 / 0.85%);
	--black-light-075: rgb(0 0 0 / 0.75%);
	--black-light-065: rgb(0 0 0 / 0.65%);
	--black-light-055: rgb(0 0 0 / 0.55%);
	--black-light-045: rgb(0 0 0 / 0.45%);
	--black-light-035: rgb(0 0 0 / 0.35%);
	--black-light-025: rgb(0 0 0 / 0.25%);
	--black-light-015: rgb(0 0 0 / 0.15%);

	--white-100: rgb(255 255 255 / 100%);
	--white-95: rgb(255 255 255 / 95%);
	--white-85: rgb(255 255 255 / 85%);
	--white-75: rgb(255 255 255 / 75%);
	--white-65: rgb(255 255 255 / 65%);
	--white-55: rgb(255 255 255 / 55%);
	--white-45: rgb(255 255 255 / 45%);
	--white-35: rgb(255 255 255 / 35%);
	--white-25: rgb(255 255 255 / 25%);
	--white-15: rgb(255 255 255 / 15%);
	--white-10: rgb(255 255 255 / 10%);
	--white-885: rgb(255 255 255 / 8.85%);
	--white-585: rgb(255 255 255 / 5.85%);
	--white-5: rgb(255 255 255 / 5%);
	--white-0: rgb(255 255 255 / 0%);
	--white-light-485: rgb(255 255 255 / 4.85%);
	--white-light-385: rgb(255 255 255 / 3.85%);
	--white-light-285: rgb(255 255 255 / 2.85%);
	--white-light-185: rgb(255 255 255 / 1.85%);
	--white-light-085: rgb(255 255 255 / 0.85%);
	--white-light-045: rgb(255 255 255 / 0.45%);

	--form-border-color: rgb(0 0 0 / 2.85%);
	--border-default-color: rgb(0 0 0 / 2.85%);
	--border-dashed-color: rgb(238 238 238 / 100%);
	--board-box-shadow-color: rgb(218 218 253 / 100%);
	--box-shadow-color: rgb(0 0 0 / 2.85%);
	--bracket-color: rgb(0 0 0 / 15%);

	--transparentMask-100: rgb(250 250 255 / 100%);
	--transparentMask-85: rgb(250 250 255 / 85%);
	--table-whiteMask-100: rgb(250 253 255 / 100%);
	--table-whiteMask-85: rgb(250 253 255 / 85%);

	--sky-100: rgb(24 144 255 / 100%);
	--sky-85: rgb(24 144 255 / 85%);
	--sky-75: rgb(24 144 255 / 75%);
	--sky-65: rgb(24 144 255 / 65%);
	--sky-55: rgb(24 144 255 / 55%);
	--sky-45: rgb(24 144 255 / 45%);
	--sky-35: rgb(24 144 255 / 35%);
	--sky-25: rgb(24 144 255 / 25%);
	--sky-15: rgb(24 144 255 / 15%);
	--sky-10: rgb(24 144 255 / 10%);
	--sky-885: rgb(24 144 255 / 8.85%);
	--sky-585: rgb(24 144 255 / 5.85%);
	--sky-5: rgb(24 144 255 / 5%);
	--sky-0: rgb(24 144 255 / 0%);
	--sky-light-485: rgb(24 144 255 / 4.85%);
	--sky-light-385: rgb(24 144 255 / 3.85%);
	--sky-light-285: rgb(24 144 255 / 2.85%);
	--sky-light-185: rgb(24 144 255 / 1.85%);
	--sky-light-085: rgb(24 144 255 / 0.85%);
	--sky-btnhover: rgb(64 169 255 / 100%);

	/* 大背景色 */
	--background-keynote: rgb(247 247 255 / 100%);
	--border-keynote: rgb(222 226 230 / 100%);
	/* 左边栏菜单 */
	--sidebar-header: rgb(231 233 236 / 100%);
	/* 大板块 */
	--board-box-shadow: 0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%);

	/* 天气图标色值 */
	--sunny: rgb(255 206 84 / 100%);
	--cloudy: rgb(117 193 244 / 100%);
	--rainny: rgb(216 216 216 / 100%);
	--snowy: rgb(65 75 184 / 100%);

	--tomato-100: rgb(255 77 79 / 100%);
	--tomato-85: rgb(255 77 79 / 85%);
	--tomato-55: rgb(255 77 79 / 55%);
	--tomato-35: rgb(255 77 79 / 25%);
	--tomato-25: rgb(255 77 79 / 35%);
	--tomato-15: rgb(255 77 79 / 15%);
	--tomato-885: rgb(255 77 79 / 8.85%);
	--tomato-585: rgb(255 77 79 / 5.85%);
	--tomato-0: rgb(255 77 79 / 0%);
	--tomato-light-485: rgb(255 77 79 / 4.85%);
	--tomato-light-185: rgb(255 77 79 / 1.85%);
	--tomato-light-085: rgb(255 77 79 / 0.85%);

	--violet-100: rgb(59 89 153 / 100%);
	--violet-45: rgb(59 89 153 / 45%);
	--violet-15: rgb(59 89 153 / 15%);

	--naked-100: rgb(248 187 134 / 100%);
	--naked-35: rgb(248 187 134 / 35%);

	--darkclouds-100: rgb(80 84 88 / 100%);
	--darkclouds-85: rgb(80 84 88 / 85%);
	--darkclouds-75: rgb(80 84 88 / 75%);
	--darkclouds-65: rgb(80 84 88 / 65%);
	--darkclouds-55: rgb(80 84 88 / 55%);
	--darkclouds-45: rgb(80 84 88 / 45%);
	--darkclouds-35: rgb(80 84 88 / 35%);
	--darkclouds-25: rgb(80 84 88 / 25%);
	--darkclouds-15: rgb(80 84 88 / 15%);
	--darkclouds-10: rgb(80 84 88 / 10%);
	--darkclouds-8: rgb(80 84 88 / 8%);

	/* 一些备用色 */
	--orange-100: rgb(250 173 20 / 100%);
	--grass-100: rgb(82 196 26 / 100%);
	--fog-100: rgb(217 217 217 / 100%);
	--lake-100: rgb(112 225 226 / 100%);
	--salmon-100: rgb(242 116 116 / 100%);
	--salmon-75: rgb(242 116 116 / 55%);
	--salmon-55: rgb(242 116 116 / 75%);
	--salmon-35: rgb(242 116 116 / 35%);

	/* 状态色（背景色取值为文字色值白背景下的15%透明度色值） */
	/* 同步中(命令状态)、默认 */
	--default-color: rgb(24 144 255 / 100%);
	--syncing-color: rgb(24 144 255 / 100%);
	--syncing-light-color: rgb(221 238 255 / 100%);
	/* 新增项(命令状态)、修改中(命令状态) */
	--adding-color: rgb(8 151 156 / 100%);
	--adding-light-color: rgb(218 240 240 / 100%);
	--editing-color: rgb(8 151 156 / 100%);
	--editing-light-color: rgb(218 240 240 / 100%);
	/* 采集器在线、正常(命令状态) */
	--loggerOnline-color: rgb(56 158 13 / 100%);
	--loggerOnline-light-color: rgb(225 241 219 / 100%);
	--normal-color: rgb(56 158 13 / 100%);
	--normal-light-color: rgb(225 241 219 / 100%);
	/* 部分停机 */
	--partShutDown-color: rgb(160 160 0 / 100%);
	/* 采集器离线、通讯异常(报警类型) */
	--loggerOffline-color: rgb(160 160 160 / 100%);
	--loggerOffline-light-color: rgb(222 222 222 / 100%);
	--commInterruption-color: rgb(160 160 160 / 100%);
	/* 超时(命令状态)、设备离线、离线(报警类型) */
	--deviceOffline-color: rgb(160 130 80 / 100%);
	--offline-color: rgb(160 130 80 / 100%);
	--timeout-color: rgb(160 130 80 / 100%);
	--timeout-light-color: rgb(241 236 229 / 100%);
	/* 阈值报警(报警类型) */
	--thresholdAlarm-color: rgb(134 100 208 / 100%);
	/* 等待(命令状态)、停机 */
	--shutDown-color: rgb(250 173 20 / 100%);
	--waiting-color: rgb(250 173 20 / 100%);
	--waiting-light-color: rgb(254 243 220 / 100%);
	/* 错误(命令状态)、紧急停止、故障 */
	--emergencyStop-color: rgb(255, 252, 77);
	--failure-color: rgb(255 77 79 / 100%);
	--error-color: rgb(255 77 79 / 100%);
	--error-light-color: rgb(255 228 229 / 100%);

	/* Size */
	--size-none: scale(0);
	--size-xxx-smaller: scale(0.45);
	--size-xx-smaller: scale(0.55);
	--size-x-smaller: scale(0.65);
	--size-smaller: scale(0.75);
	--size-small: scale(0.85);
	--size-normal: scale(1);
	--size-large: scale(1.15);
	--size-larger: scale(1.25);
	--size-x-larger: scale(1.35);
	--size-xx-larger: scale(1.45);
	--size-xxx-larger: scale(1.55);

	/* borderRadius: ; */
	--border-radius-default: 2rem;
	--border-radius-large: 4rem;

	/* WidthHeight */
	--wh-fit: fit-content;
	--wh-12: 12px;
	--wh-22: 22px;
	--wh-24: 24px;
	--wh-32: 32px;
	--wh-38: 38px;
	--wh-40: 40px;
	--wh-48: 48px;
	--wh-58: 58px;
	--wh-68: 68px;
	--wh-78: 78px;
	--wh-80: 80px;
	--wh-88: 88px;
	--wh-94: 94px;
	--wh-98: 98px;
	--wh-108: 108px;
	--wh-118: 118px;
	--wh-128: 128px;
	--wh-138: 138px;
	--wh-148: 148px;
	--wh-158: 158px;
	--wh-168: 168px;
	--wh-178: 178px;
	--wh-188: 188px;
	--wh-238: 238px;
	--wh-258: 258px;
	--wh-348: 348px;
	--wh-368: 368px;
	--wh-418: 418px;
	--wh-466: 466px;
	--wh-518: 518px;
	--wh-568: 568px;
	--wh-668: 668px;

	/* FontSize */
	--fontsize-08: 8px;
	--fontsize-10: 10px;
	--fontsize-11: 11px;
	--fontsize-12: 12px;
	--fontsize-13: 13px;
	--fontsize-14: 14px;
	--fontsize-16: 16px;
	--fontsize-18: 18px;
	--fontsize-20: 20px;
	--fontsize-22: 22px;
	--fontsize-24: 24px;

	/* FontWeight */
	--fontweight-bold: bold;
	--fontweight-600: 600;

	/* FontFamily */
	--fontfamily-number: 'Arial Narrow';

	/* LetterSpacing */
	--spacing-xxxx-smaller: -5.8px;
	--spacing-xxx-smaller: -4.8px;
	--spacing-xx-smaller: -3.8px;
	--spacing-x-smaller: -2.8px;
	--spacing-smaller: -1.8px;
	--spacing-small: -0.8px;
	--spacing-normal: 0px;
	--spacing-large: 0.8px;
	--spacing-larger: 1.8px;
	--spacing-x-larger: 2.8px;
	--spacing-xx-larger: 3.8px;
	--spacing-xxx-larger: 4.8px;
	--spacing-xxxx-larger: 5.8px;

	/* Percent */
	--percent-20: 20%;
	--percent-40: 40%;
	--percent-60: 60%;
	--percent-80: 80%;
	--percent-100: 100%;
	--percent-120: 120%;
	--percent-140: 140%;
	--percent-160: 160%;
	--percent-180: 180%;
	--percent-200: 200%;
	--percent-220: 220%;
	--percent-240: 240%;
	--percent-260: 260%;
	--percent-320: 320%;

	/* Transition */
	--transition-x-slower: all .55s;
	--transition-slower: all .45s;
	--transition-slow: all .35s;
	--transition-normal: all .25s;
	--transition-fast: all .15s;
	--transition-faster: all .05s;
	--transition-x-faster: all .045s;
}

.bodyCss {
	overflow-y: hidden !important;
	width: calc(100% - 17px);
}

/* 基础颜色配置 */
.Color-Tomato {
	color: var(--tomato-100);
	fill: var(--tomato-100);
}

.Color-Orange {
	color: var(--orange-100);
	fill: var(--orange-100);
}

.Color-Grass {
	color: var(--grass-100);
	fill: var(--grass-100);
}

.Color-Fog {
	color: var(--fog-100);
	fill: var(--fog-100);
}

.Color-Sky {
	color: var(--sky-100);
	fill: var(--sky-100);
}

.Color-Naked {
	color: var(--naked-100);
	fill: var(--naked-100);
}

.Color-Sunny {
	color: var(--sunny) !important;
	fill: var(--sunny) !important;
}

.Color-Cloudy {
	color: var(--cloudy) !important;
	fill: var(--cloudy) !important;
}

.Color-Rainny {
	color: var(--rainny) !important;
	fill: var(--rainny) !important;
}

.Color-Snowy {
	color: var(--snowy) !important;
	fill: var(--snowy) !important;
}



/* 需要瘦型数字的组件 */
.ant-typography,
.ant-table,
.ant-pagination,
.ant-segmented,
.ant-picker,
.ant-picker-panel,
.ant-input,
.ant-input-number,
.ant-select,
.ant-select-dropdown,
.ant-picker-calendar,
.ant-btn,
.ant-modal,
.ant-menu,
.ant-radio-button,
.ant-input-number-group-addon,
.ant-badge-count {
	font-family: 'Arial Narrow' !important;
}

.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
	height: 0px !important;
	max-width: 0px !important;
	max-height: calc(100vh - 100px);
	padding: 0;
	overflow: hidden;
	border-right: 0;
	background-color: var(--white-100);
	color: var(--white-100);
	opacity: 0;
}

.anticon {
	vertical-align: .0625rem !important;
}

.table>:not(:last-child)>:last-child>* {
	border-bottom-color: currentColor !important;
}

.table>:not(:last-child)>:last-child>* {
	border-bottom-color: currentColor !important;
}

.rankingList___2GuWM {
	margin: 25px 0 0;
	padding: 0;
	list-style: none;
}

.rankingList___2GuWM li {
	display: flex;
	align-items: center;
	margin-top: 16px;
	zoom: 1;
}

.rankingList___2GuWM li .rankingItemTitle___2Yoy1 {
	flex: 1 1;
	margin-right: 8px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

/* 验证条样式 */
.nc_scale {
	width: 100%;
	background: rgb(244 252 237 / 15%) !important;
	/* 默认背景色 */
}

.nc_wrapper {
	width: 100% !important;
	font-size: var(--fontsize-12);
}

.nc-container .nc_scale span {
	height: 100% !important;
	border: 1px solid rgb(229 246 224 / 100%) !important;
}

.nc_scale div.nc_bg {
	background: rgb(238 250 228 / 100%) !important;
	/* 滑过时的背景色 */
}

.nc-container .nc_scale .btn_ok {
	font-size: var(--fontsize-16) !important;
	color: var(--grass-100);
}

.nc-lang-cnt {
	display: block !important;
	text-align: center !important;
	padding: 0 0 0 40px !important;
}

.errloading {
	border: rgb(250 241 213 / 100%) 1px solid !important;
	color: var(--orange-100) !important;
}

/* 字体 */
.Fontsize-XX-Larger {
	font-size: var(--percent-180);
}

/* 透明度 */
.Opacity-15 {
	opacity: .15;
}

.Opacity-35 {
	opacity: .35;
}

.Opacity-55 {
	opacity: .55;
}

.Opacity-75 {
	opacity: .75;
}

/* 各种间距调用样式 */
.m-l-5 {
	margin-left: .3125rem;
}

.m-r-4 {
	margin-right: .25rem;
}

.m-r-5 {
	margin-right: .3125rem;
}

.m-b-10 {
	margin-bottom: .625rem;
}

.m-b-20 {
	margin-bottom: 1.25rem;
}

.m-b-30 {
	margin-bottom: 1.875rem;
}

/* 隐藏显示调用样式 */
.Show,
.Hide,
.fadeShow,
.fadeHide,
.isShow,
.isHide {
	transition: var(--transition-fast);
}

.Show {
	opacity: 1;
	transform: var(--size-normal);
}

.Hide {
	padding: 0 !important;
	margin: 0 !important;
	opacity: 0;
	transform: var(--size-none);
}

.TransRight {
	transform-origin: 100% 50%;
}

.TransLeft {
	transform-origin: 0% 50%;
}

.TransCenter {
	transform-origin: 50% 50%;
}

.ClearWidth {
	width: 0 !important;
	min-width: 0 !important;
	max-width: 0 !important;
	margin: 0 !important;
	padding: 0 !important;
	border: none !important;
}

.ClearHeight {
	height: 0 !important;
	min-height: 0 !important;
	max-height: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
	border: none !important;
}

.fadeShow {
	width: 100% !important;
	opacity: 1;
	transform: var(--size-normal);
}

.fadeHide {
	width: 0 !important;
	padding: 0 !important;
	margin: 0 !important;
	opacity: 0;
	transform: var(--size-none);
}

.isShow {
	display: block !important;
	opacity: 1;
}

.isHide {
	display: none !important;
	opacity: 0;
}

/* 预备消失 */
.DisplayReady,
.DisplayFade {
	transition: all .8s linear;
}

.DisplayReady {
	max-height: 1000px;
	opacity: 1;
}

/* 逐渐消失 */
.DisplayFade {
	max-height: 0px;
	opacity: 0;
}

/* 主题部分的定位 */
.page-wrapper {
	padding-bottom: 40px;
}

/* 数字样式 */
.number-style,
.number-style-large,
.number-style-larger,
.number-style-important {
	font-family: 'Arial Narrow';
}

.number-style-large {
	font-size: var(--percent-180) !important;
}

.number-style-important {
	font-size: var(--percent-180);
	margin: 0 2px !important;
}

.number-style-larger {
	font-size: var(--percent-320) !important;
}

/* AntDesign样式覆盖 */
.ant-menu-submenu-popup {
	position: absolute;
	z-index: 1050;
	background: transparent;
	border-radius: 2px;
	box-shadow: none;
	transform-origin: 0 0;
	max-width: 0px !important;
	height: 0px !important;
	left: 9999
}

/* 首页板块抬头样式 */
.ant-page-header {
	padding: 0 !important;
	margin-bottom: .75rem !important;
}

.ant-page-header-heading-left {
	margin: -2px 0 0 0 !important;
}

.ant-page-header-heading-title {
	font-weight: 400 !important;
	font-size: var(--fontsize-18) !important;
}

@media (max-width:540px) {
	.ant-page-header-heading {
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}

	.ant-page-header-heading-left {
		display: block !important;
	}

	.ant-page-header-heading-title {
		margin-right: 0 !important;
	}
}

/* 按钮 */
.ant-btn,
.ant-btn.ant-btn-default {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-color: var(--black-885);
	border-radius: 6px !important;
	box-sizing: border-box;
	box-shadow: none;
	transition: var(--transition-normal);
}

.ant-btn.ant-btn-default:hover {
	border-color: var(--sky-15);
	color: var(--sky-55);
}

.ant-btn>.icon-svg {
	transition: var(--transition-normal);
}

.ant-btn.ant-btn-lg {
	border-radius: 8px !important;
	font-size: var(--fontsize-14) !important;
	height: var(--wh-40);
}

.ant-btn.ant-btn-sm {
	border-radius: 6px !important;
	font-size: var(--fontsize-12) !important;
	height: var(--wh-24);
}

.ant-btn.ant-btn-lg.ant-btn-round {
	border-radius: 40px !important;
}

.ant-btn.ant-btn-sm.ant-btn-round {
	border-radius: 24px !important;
}

.ant-btn.ant-btn-circle {
	border-radius: 50% !important;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

/* 红色警告按钮的样式 */
.ant-btn.ant-btn-dangerous {
	border-color: var(--tomato-15) !important;
	color: var(--tomato-100);
}

.ant-btn.ant-btn-dangerous:hover {
	border-color: var(--tomato-100);
	background: var(--tomato-100);
	box-shadow: 0 0 8px var(--tomato-5) !important;
	color: var(--white-100);
}

/* 蓝色按钮的禁用状态样式 */
.ant-btn.ant-btn-primary[disabled],
.ant-btn.ant-btn-primary[disabled]:hover {
	border-color: var(--sky-light-485);
	background: var(--sky-light-185);
	box-shadow: 0 2px 0 var(--sky-0);
	color: var(--sky-25);
	transition: var(--transition-normal);
}

.ant-btn.ant-btn-primary[disabled]>.icon-svg,
.ant-btn.ant-btn-primary[disabled]>.anticon,
.ant-btn.ant-btn-primary[disabled]:hover>.icon-svg,
.ant-btn.ant-btn-primary[disabled]:hover>.anticon {
	fill: var(--sky-25);
	color: var(--sky-25);
}

/* 报错按钮的禁用状态样式 */
.ant-btn.ant-btn-erro[disabled],
.ant-btn.ant-btn-erro[disabled]:hover {
	border-color: var(--tomato-light-485);
	background: var(--tomato-light-085);
	box-shadow: 0 2px 0 var(--tomato-0);
	color: var(--tomato-25);
	transition: var(--transition-normal);
}

.ant-btn.ant-btn-erro[disabled]>.icon-svg,
.ant-btn.ant-btn-erro[disabled]>.anticon,
.ant-btn.ant-btn-erro[disabled]:hover>.icon-svg,
.ant-btn.ant-btn-erro[disabled]:hover>.anticon {
	fill: var(--tomato-25);
	color: var(--tomato-25);
}

/* 添加按钮（虚线框）的禁用状态样式 */
.ant-btn.ant-btn-dashed[disabled],
.ant-btn.ant-btn-dashed[disabled]:hover {
	border-color: var(--border-dashed-color);
	background: var(--white-100);
	box-shadow: 0 2px 0 var(--black-0);
	color: var(--black-25);
	transition: var(--transition-normal);
}

.ant-btn.ant-btn-dashed[disabled]>.icon-svg,
.ant-btn.ant-btn-dashed[disabled]>.anticon,
.ant-btn.ant-btn-dashed[disabled]:hover>.icon-svg,
.ant-btn.ant-btn-dashed[disabled]:hover>.anticon {
	fill: var(--black-25);
	color: var(--black-25);
}

/* 时间选择器样式 */
.ant-picker {
	border-radius: 6px !important;
	height: 32px;
}

.ant-picker-small {
	height: 24px;
}

.ant-picker-small .ant-picker-input>input {
	height: 100%;
	font-size: small;
}

.ant-picker-cell .ant-picker-cell-inner {
	border-radius: 4px !important;
}

.ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-cell:hover .ant-picker-cell-inner:before {
	color: var(--sky-85);
	background: transparent !important;
}

.ant-picker-cell-today .ant-picker-cell-inner {
	color: var(--sky-55);
}

.ant-picker-cell-today .ant-picker-cell-inner::before {
	border: 1px solid transparent !important;
	background: transparent !important;
	border-radius: 4px !important;
}

.ant-picker-cell-disabled::before {
	background: transparent !important;
}

.ant-picker-header-view {
	font-size: .875rem !important;
	font-weight: 400 !important;
}

.ant-picker-panel-container {
	border-radius: 8px !important;
}

.ant-picker-today-btn {
	font-size: .75rem !important;
	font-weight: 600 !important;
}


/* 左边栏样式 */
.metismenu {
	padding: 0 !important;
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-item,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
	padding: 0 calc(50% - 20px / 2) !important;
}

.ant-menu-inline .ant-menu-submenu-title,
li.ant-menu-item {
	margin: 0 !important;
	padding: 0 15px !important;
}

.ant-menu-submenu-title a,
.ant-menu-title-content {
	font-weight: 600;
}

.ant-menu-submenu-open li.ant-menu-item .ant-menu-title-content {
	font-weight: normal !important;
}

.ant-menu .ant-menu-item-active {
	background-color: var(--black-light-285) !important;
}

/* 选中状态下的菜单样式 */
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	color: var(--sky-100) !important;
	background-color: rgb(239 247 255 / 100%) !important;
}

.ant-menu-submenu li {
	padding-left: 42px !important;
}

.ant-menu a {
	color: var(--font-55) !important;
}

.ant-menu a:hover {
	color: var(--font-85) !important;
}

.ant-menu a.active,
.ant-menu-item-selected,
.ant-menu-submenu-selected .ant-menu-submenu-title .icon-svg {
	color: var(--sky-100) !important;
	fill: var(--sky-100) !important
}

.ant-menu-submenu li {
	margin: 0 !important;
}

.ant-menu-inline {
	border-right: 0px;
}

.ant-menu-sub.ant-menu-inline {
	background: var(--black-light-085) !important;
	border-top: 1px solid var(--black-light-285) !important;
	border-bottom: 1px solid var(--black-light-285) !important;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
	width: calc(100% + 0px) !important;
}

/* 面包屑 */
.ant-breadcrumb ol {
	flex-wrap: nowrap !important;
}

.ant-breadcrumb a .icon-svg {
	color: var(--sky-55);
	fill: var(--sky-55);
	margin-right: 0 !important;
}

.ant-breadcrumb a:hover .icon-svg {
	color: var(--sky-100);
	fill: var(--sky-100);
}

.ant-breadcrumb ol li {
	display: flex;
	align-items: center;
	flex-wrap: nowrap !important;
}

/* 语言选择器的文字样式 */
.ant-btn-two-chinese-chars>*:not(.anticon) {
	margin-right: -0.34em;
	letter-spacing: 0 !important;
}


/* 默认输入框样式 */
.ant-input-affix-wrapper {
	transition: var(--transition-slow);
}

input:-internal-autofill-previewed,
input:-internal-autofill-selected {
	transition: background-color 5000s ease-out 0.5s;
}


/* 特殊重要的输入框 暂时不用 */
.ant-input-affix-wrapper.VipInput {
	padding: 8px;
	color: var(--font-normal);
	font-size: var(--fontsize-12);
	line-height: 1.5715;
	background-image: none;
	border-color: var(--sky-5) !important;
	background-color: var(--sky-light-185) !important;
}

.ant-input-affix-wrapper.VipInput:hover {
	border-color: rgb(123 192 255 / 100%) !important;
}

.ant-input-affix-wrapper.VipInput.ant-input-affix-wrapper-focused {
	border-color: var(--sky-100) !important;
}

.ant-input-affix-wrapper>.ant-input:not(textarea) {
	padding: 6px !important;
	margin: 0 !important;
	background-color: transparent !important;
}

.ant-input.ant-input-status-error,
.ant-input-affix-wrapper.ant-input-affix-wrapper-status-error {
	border-color: var(--tomato-35);
	background-color: var(--tomato-light-085) !important;
}

.ant-input-prefix .icon-svg {
	color: var(--sky-100);
	fill: var(--sky-100);
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-status-error .icon-svg {
	color: var(--tomato-35);
	fill: var(--tomato-35);
}

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
	background: var(--white-100);
	border-color: var(--tomato-35) !important;
}

.ant-form-item-feedback-icon-error {
	color: var(--tomato-100) !important;
}

.ant-form-item-feedback-icon {
	font-size: var(--fontsize-12) !important;
}

.ant-form-item-has-success .ant-input-suffix .ant-form-item-feedback-icon {
	display: none;
}

.ant-input.ant-input-disabled,
.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled>.ant-input,
.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled:hover>.ant-input {
	border-color: transparent !important;
	background-color: transparent !important;
	padding: 0 !important;
}

/* @media (max-width: 992px) {
	.ant-input-affix-wrapper>.ant-input,
	.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled:hover>.ant-input {
		font-size:  var(--fontsize-16) !important;
	}
}

@media (max-width: 768px) {
	.ant-input-affix-wrapper>.ant-input,
	.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled:hover>.ant-input {
		font-size: 15px !important;
	}
}

@media (max-width: 576px) {
	.ant-input-affix-wrapper>.ant-input,
	.ant-input-affix-wrapper.ant-input-affix-wrapper-disabled:hover>.ant-input {
		font-size:  var(--fontsize-14) !important;
	}
} */


/* 输入框字数提示 */
.ant-input-show-count-suffix,
.ant-input-textarea-show-count::after {
	font-size: var(--fontsize-10) !important;
}

/* 输入框提示语 */
.ant-form-item-explain {
	font-size: var(--fontsize-10) !important;
}

.ant-form-item-explain.ant-form-item-explain-connected {
	font-size: var(--fontsize-10);
	text-align: right;
}

/* 各种FORM组件样式 */
.ant-input,
.ant-input-number,
.ant-input-affix-wrapper,
.ant-select-selector {
	border-radius: 6px !important;
}

/* 默认下拉框选择器样式 */
.ant-input,
.ant-input-affix-wrapper,
.ant-input-number,
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker {
	border-color: var(--form-border-color) !important;
}

.ant-picker.form-sky,
.ant-select:not(.ant-select-customize-input).form-sky .ant-select-selector {
	border-color: var(--sky-10) !important;
}

.ant-input:focus,
.ant-input-affix-wrapper.ant-input-affix-wrapper-focused,
.ant-input-number.ant-input-number-focused,
.ant-select:not(.ant-select-customize-input).ant-select-focused .ant-select-selector,
.ant-input:hover,
.ant-input-affix-wrapper:hover,
.ant-input-number:hover,
.ant-select:not(.ant-select-customize-input):hover .ant-select-selector,
.ant-picker:hover,
.ant-picker.ant-picker-focused {
	border-color: var(--sky-100) !important;
}

/* 默认数字输入框的样式 */
.ant-input-number {
	width: 100% !important;
	height: 32px !important;
}

.ant-input-number-handler-wrap {
	border-radius: 0 6px 6px 0 !important;
}

.ant-input-number-handler-up {
	border-top-right-radius: 6px !important;
}

.ant-input-number-handler-down {
	border-bottom-right-radius: 6px !important;
}

/* 大尺寸数字输入框的样式 */
.ant-input-number.ant-input-number-lg,
.ant-input-number.ant-input-number-lg .ant-input-number-input,
.ant-input-number.ant-input-number-lg .ant-input-number-input-wrap {
	height: 43px;
	font-size: var(--fontsize-18) !important;
	box-sizing: border-box;
}

.ant-input-number.ant-input-number-disabled,
.ant-input-number.ant-input-number-lg.ant-input-number-disabled,
.ant-input-number.ant-input-number-disabled .ant-input-number-affix-wrapper-disabled,
.ant-input-number.ant-input-number-lg.ant-input-number-disabled .ant-input-number-affix-wrapper-disabled,
.ant-input-number.ant-input-number-lg.ant-input-number-disabled .ant-input-number-input,
.ant-input-number.ant-input-number-disabled .ant-input-number-input,
.ant-input-number.ant-input-number-lg.ant-input-number-disabled .ant-input-number-input-wrap,
.ant-input-number.ant-input-number-disabled .ant-input-number-input-wrap {
	color: var(--font-35);
	background-color: transparent !important;
	border-color: transparent !important;
	padding: 0 !important;
}

/* 新增状态下的各种组件样式 */
/* 新增下拉框 */
.ant-form.AddForm .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
	height: 43px !important;
	font-size: var(--fontsize-16) !important;
}

.ant-form.AddForm .ant-select-disabled,
.ant-form.AddForm .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	color: var(--font-35);
	background: none !important;
	border-color: transparent !important;

	padding: 0 !important;
}

.ant-form.AddForm .ant-select.ant-select-disabled .icon-svg,
.ant-form.AddForm .ant-select.ant-select-disabled .icon-svg>svg {
	fill: rgb(0 0 0 / 0%);
}

/* 新增数字输入框 */
.ant-form.AddForm .ant-input-number.hornForm.tailForm {
	width: fit-content;
	min-width: var(--wh-38);
}

.ant-form.AddForm .ant-input-number.ant-input-number-lg.ant-input-number-disabled {
	color: var(--font-35);
	background-color: transparent !important;
	border-color: transparent !important;
	padding: 0 !important;
}

.ant-form-item.ant-form-item-has-error .ant-input-number-group-wrapper.ant-input-number-group-wrapper-status-error.hornForm.tailForm,
.ant-form-item.ant-form-item-has-error .ant-input-number-group-wrapper.ant-input-number-group-wrapper-status-error.hornForm.tailForm .ant-input-number-group-addon,
.ant-form-item.ant-form-item-has-error .ant-input-number-group-wrapper.ant-input-number-group-wrapper-status-error.hornForm.tailForm .ant-input-number-input,
.ant-form.AddForm .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
	border-color: var(--tomato-15) !important;
	background-color: var(--white-100) !important;
	color: var(--tomato-85);
}

.ant-form-item.ant-form-item-has-error .ant-input-number-group-wrapper.ant-input-number-group-wrapper-status-error.hornForm.tailForm .ant-input-number-group-addon {
	color: var(--tomato-25) !important;
}

.ant-form-item.ant-form-item-has-error .ant-input-number-group-wrapper.ant-input-number-group-wrapper-status-error.hornForm.tailForm .ant-input-number.hornForm.tailForm {
	border: 1px solid var(--tomato-15) !important;
	border-left: 1px solid transparent !important;
	border-right: 1px solid transparent !important;
	background-color: var(--white-100) !important;
}

.ant-form-item.ant-form-item-has-error .ant-input-number-group-wrapper.ant-input-number-group-wrapper-status-error.hornForm.tailForm .ant-input-number.hornForm.tailForm:hover,
.ant-form-item.ant-form-item-has-error .ant-input-number-group-wrapper.ant-input-number-group-wrapper-status-error.hornForm.tailForm .ant-input-number.ant-input-number-focused,
.ant-form-item.ant-form-item-has-error .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer).ant-select-focused .ant-select-selector,
.ant-form.AddForm .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
	border-color: var(--tomato-85) !important;
}

.ant-form-item .ant-input-number.hornForm.tailForm.ant-input-number-disabled,
.ant-form-item:hover .ant-input-number.hornForm.tailForm.ant-input-number-disabled {
	border-color: var(--border-default-color) !important;
	border-left-color: transparent !important;
	border-right-color: transparent !important;
}

.ant-form-item .ant-input-number-group-wrapper.hornForm.tailForm .ant-input-number.ant-input-number-focused {
	border-color: var(--sky-85) !important
}

.ant-form-item.ant-form-item-has-error .ant-input-number-group-wrapper .ant-input-number-group-wrapper.hornForm.tailForm .ant-input-number.ant-input-number-focused {
	border-color: var(--tomato-85) !important
}

/* 新增输入框 */
.ant-form.AddForm .ant-input,
.ant-form.AddForm .ant-input-affix-wrapper,
.ant-form.AddForm .ant-input-affix-wrapper>.ant-input,
.ant-form.AddForm .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled:hover>.ant-input {
	letter-spacing: var(--spacing-large) !important;
	font-size: var(--fontsize-16) !important;
	box-sizing: border-box;
}

.ant-form.AddForm .ant-input,
.ant-form.AddForm .ant-input-affix-wrapper {
	height: 43px !important;
}

.ant-form.AddForm .ant-input-affix-wrapper>.ant-input {
	height: 32px !important;
	padding: 6px 0 !important;
}

.ant-form.AddForm .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled:hover>.ant-input {
	padding: 0 !important;
}

.ant-form-item.ant-form-item-has-error .ant-select-arrow .icon-svg {
	fill: var(--tomato-25);
}


/* 编辑状态下的各种组件样式 */
/* 编辑下拉框 */
.ant-form.EditForm .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	height: 43px !important;
	font-size: var(--fontsize-16) !important;
	padding: 0 !important;
	background: none !important;
	border-color: transparent !important;
}

.ant-form.EditForm .ant-select-disabled,
.ant-form.EditForm .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	color: var(--font-35);
}

.ant-form.EditForm .ant-select.ant-select-disabled .icon-svg,
.ant-form.EditForm .ant-select.ant-select-disabled .icon-svg>svg {
	fill: var(--black-0);
}

.ant-form.EditForm .ant-select .ant-select-arrow {
	right: 0;
}

/* 编辑数字输入框 */
.ant-form.EditForm .ant-input-number.ant-input-number-lg,
.ant-form.EditForm .ant-input-number.ant-input-number-lg .ant-input-number-input,
.ant-form.EditForm .ant-input-number.ant-input-number-lg .ant-input-number-input-wrap {
	height: 43px;
	font-size: var(--fontsize-18) !important;
	box-sizing: border-box;
	padding: 0 !important;
}

.ant-form.EditForm .ant-input-number.ant-input-number-lg {
	border-color: transparent !important;
}

.ant-form.EditForm .ant-input-number.ant-input-number-lg.ant-input-number-focused,
.ant-form.EditForm .ant-input-number.ant-input-number-lg:hover {
	border-color: var(--sky-100) !important;
	padding: 0 11px !important;
}

.ant-form.EditForm .ant-input-number.ant-input-number-lg.ant-input-number-disabled {
	color: var(--font-35);
	background-color: transparent !important;
	border-color: transparent !important;
	padding: 0 !important;
}

/* 编辑输入框 */
.ant-form.EditForm .ant-input,
.ant-form.EditForm .ant-input-affix-wrapper>.ant-input,
.ant-form.EditForm .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled:hover>.ant-input {
	height: 43px !important;
	letter-spacing: var(--spacing-larger) !important;
	font-size: var(--fontsize-16) !important;
	padding: 6px 8px 6px 0 !important;
}

.ant-form.EditForm .ant-input:hover,
.ant-form.EditForm .ant-input-affix-wrapper:hover>.ant-input,
.ant-form.EditForm .ant-input-affix-wrapper.ant-input-affix-wrapper-focused>.ant-input,
.ant-form.EditForm .ant-form-item-has-error .ant-input-affix-wrapper-status-error .ant-input {
	padding: 6px 8px 6px 3px !important;
}

.ant-form.EditForm .ant-input:focus,
.ant-form.EditForm .ant-input-affix-wrapper.ant-input-affix-wrapper-focused>.ant-input,
.ant-form.EditForm .ant-form-item-has-error .ant-input-affix-wrapper-status-error .ant-input {
	font-size: var(--fontsize-14) !important;
}

.ant-form.EditForm .ant-input:focus,
.ant-form.EditForm .ant-input-affix-wrapper.ant-input-affix-wrapper-focused>.ant-input,
.ant-form.EditForm .ant-form-item-has-error .ant-input {
	letter-spacing: var(--spacing-normal) !important;
}

.ant-form.EditForm .ant-input-prefix {
	opacity: 0;

	color: var(--sky-55);
	margin-left: -20px;
	transition: var(--transition-normal);
}

.ant-form.EditForm .ant-input-suffix .ant-input-show-count-suffix,
.ant-form.EditForm .ant-input-textarea-show-count::after {
	opacity: 0;
	transition: var(--transition-normal);
}

.ant-form.EditForm .ant-input-affix-wrapper:hover .ant-input-prefix,
.ant-form.EditForm .ant-input-affix-wrapper-focused .ant-input-prefix,
.ant-form.EditForm .ant-form-item-has-error .ant-input-prefix {
	opacity: 1;

	margin-left: 0px;
	transition: var(--transition-normal);
}

.ant-form.EditForm .ant-input-affix-wrapper-focused .ant-input-suffix .ant-input-show-count-suffix,
.ant-form.EditForm .ant-form-item-has-error .ant-input-suffix .ant-input-show-count-suffix,
.ant-form.EditForm .ant-input-affix-wrapper-focused .ant-input-textarea-show-count::after {
	opacity: 1;
}

.ant-form.EditForm .ant-input-affix-wrapper-focused .ant-input-prefix {
	color: var(--sky-100);
	padding-left: 8px;
}

.ant-form.EditForm .ant-form-item-has-error .ant-input-prefix {
	color: var(--tomato-35);
	padding-left: 8px;
}

.ant-form.EditForm .ant-input-affix-wrapper {
	color: var(--font-85);
	border-color: transparent !important;
	background-color: transparent !important;
	padding: 0 8px 0 0 !important;
	resize: none;
}

.ant-form.EditForm .ant-input-affix-wrapper:hover {
	background-color: var(--white-35) !important;
}

.ant-form.EditForm .ant-input.ant-input-disabled,
.ant-form.EditForm .ant-input-affix-wrapper.ant-input-affix-wrapper-disabled {
	border-color: transparent !important;
	padding: 0 !important;
}

.ant-form.EditForm .ant-input-affix-wrapper-focused {
	border-color: var(--sky-100) !important;
	background-color: var(--white-75) !important;
}

.ant-form.EditForm .ant-input.ant-input-status-error,
.ant-form.EditForm .ant-input-affix-wrapper.ant-input-affix-wrapper-status-error {
	border-color: var(--tomato-35) !important;
	background-color: var(--tomato-light-085) !important;
}

.ant-form.EditForm .ant-input-disabled,
.ant-form.EditForm .ant-select-disabled,
.ant-form.EditForm .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	color: var(--font-35) !important;
}


/* 下拉选择器内的箭头交互样式 */
.ant-select-arrow .icon-svg {
	fill: var(--black-15);
	color: var(--black-15);
}

.ant-select:hover .ant-select-arrow .icon-svg,
.ant-select:focus .ant-select-arrow .icon-svg,
.ant-select.ant-select-open .ant-select-arrow .icon-svg {
	fill: var(--sky-85);
	color: var(--sky-85);
}

/* Number输入框的角尾样式 */
.ant-input-number-group-wrapper .ant-input-number-group-addon {
	background-color: transparent !important;
	border: 1px solid var(--form-border-color) !important;
	border-radius: 6px !important;

	color: var(--font-35);
	font-size: var(--fontsize-12);
	min-width: var(--wh-38);

	cursor: default !important;
}

.ant-input-number-group-wrapper .ant-input-number-group-addon:first-child {
	border-radius: 6px 0 0 6px !important;
	border-right: none !important;
}

.ant-input-number-group-wrapper .ant-input-number-group-addon:last-child {
	border-radius: 0 6px 6px 0 !important;
	border-left: none !important;
}

.ant-input-number.hornForm.tailForm {
	border-left: 1px solid transparent !important;
	border-right: 1px solid transparent !important;
	border-radius: 0 !important;
}

.ant-input-number.hornForm.tailForm:hover {
	border-left: 1px solid var(--sky-85) !important;
	border-right: 1px solid var(--sky-85) !important;
}

/* Form输入框的基本尺寸设定 */
.ant-form-item.minWidth-xx-sm .ant-select,
.ant-input-number.minWidth-xx-sm .ant-input-number-input {
	width: var(--wh-80);
	min-width: var(--wh-80);
}

.ant-form-item.minWidth-x-sm .ant-select,
.ant-input-number.minWidth-x-sm .ant-input-number-input {
	width: var(--wh-88);
	min-width: var(--wh-88);
}

.ant-form-item.minWidth-sm .ant-select,
.ant-input-number.minWidth-sm .ant-input-number-input {
	width: var(--wh-98);
	min-width: var(--wh-98);
}

.ant-form-item.minWidth .ant-select,
.ant-input-number.minWidth .ant-input-number-input {
	width: var(--wh-108);
	min-width: var(--wh-108);
}

.ant-form-item.minWidth-lg .ant-select,
.ant-input-number.minWidth-lg .ant-input-number-input {
	width: var(--wh-118);
	min-width: var(--wh-118);
}

.ant-form-item.minWidth-x-lg .ant-select,
.ant-input-number.minWidth-x-lg .ant-input-number-input {
	width: var(--wh-128);
	min-width: var(--wh-128);
}

.ant-form-item.minWidth-xx-lg .ant-select,
.ant-input-number.minWidth-xx-lg .ant-input-number-input {
	width: var(--wh-138);
	min-width: var(--wh-138);
}

.ant-form-item.minWidth-xxx-lg .ant-select,
.ant-input-number.minWidth-xxx-lg .ant-input-number-input {
	width: var(--wh-148);
	min-width: var(--wh-148);
}

.ant-form-item.minWidth-xxxx-lg .ant-select,
.ant-input-number.minWidth-xxxx-lg .ant-input-number-input {
	width: var(--wh-158);
	min-width: var(--wh-158);
}

/* 表内过滤器下拉 */
.ant-table-filter-dropdown {
	border-radius: 8px !important;
	box-shadow: 0 3px 6px -4px var(--sky-light-385), 0 6px 16px 0 var(--sky-light-385), 0 9px 28px 8px var(--sky-light-385) !important;
}

.ant-dropdown-menu-title-content>span {
	font-size: var(--fontsize-12);
}

.ant-dropdown-menu-title-content>.ant-checkbox-wrapper>.ant-checkbox>.ant-checkbox-inner {
	width: 14px;
	height: 14px;
}

.ant-table-filter-dropdown-btns {
	border-radius: 0 0 8px 8px !important;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-active,
.ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
	color: var(--font-85) !important;
	background-color: transparent !important;
}

/* Dropdown下拉 语言选择器 */
ul.ant-dropdown-menu {
	border-radius: 8px !important;
	background: var(--white-100) !important;
	box-shadow: 0 3px 6px -4px var(--sky-light-385), 0 6px 16px 0 var(--sky-light-385), 0 9px 28px 8px var(--sky-light-385);
	top: -4px;
}

ul.ant-dropdown-menu .ant-dropdown-menu-item,
ul.ant-dropdown-menu .ant-dropdown-menu-item .icon-svg {
	color: var(--font-55);
	fill: var(--font-55);
}

ul.ant-dropdown-menu .ant-dropdown-menu-item:hover,
ul.ant-dropdown-menu .ant-dropdown-menu-item:hover .icon-svg {
	background-color: var(--white-100);
	color: var(--font-85);
	fill: var(--font-85);
}

/* 用户中心下拉 */
ul.ant-dropdown-menu.userCenter {
	background: var(--white-100) !important;
	top: 0px !important;
}

ul.ant-dropdown-menu.userCenter>li.ant-dropdown-menu-item.ant-dropdown-menu-item-active {
	background: transparent !important;
}

ul.ant-dropdown-menu.userCenter>li.ant-dropdown-menu-item,
ul.ant-dropdown-menu.userCenter>li.ant-dropdown-menu-item .icon-svg {
	color: var(--font-55);
	fill: var(--font-55);
}

ul.ant-dropdown-menu.userCenter>li.ant-dropdown-menu-item:hover,
ul.ant-dropdown-menu.userCenter>li.ant-dropdown-menu-item:hover .icon-svg {
	color: var(--font-85);
	fill: var(--font-85);
}

ul.ant-dropdown-menu.userCenter>li.ant-dropdown-menu-item:last-child {
	color: var(--font-85);
}

ul.ant-dropdown-menu.userCenter>li.ant-dropdown-menu-item:last-child:hover {
	color: var(--sky-100);
	fill: var(--sky-100);

	transition: var(--transition-normal);
}

ul.ant-dropdown-menu.userCenter>li.ant-dropdown-menu-item:last-child .icon-svg {
	color: var(--black-85);
	fill: var(--black-85);
}

/* Select下拉样式 */
.ant-select-dropdown {
	padding: 12px 0 !important;
	border: 1px solid var(--sky-light-285);
	border-radius: 8px !important;
	background: var(--white-100) !important;
	box-shadow: 0 3px 6px -4px var(--sky-10), 0 6px 16px 0 var(--sky-885), 0 9px 28px 8px var(--sky-585) !important;

	min-width: var(--wh-108) !important;

	z-index: 1000 !important;
}

.ant-select-dropdown .ant-select-item {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 4px 12px;

	min-height: 28px;

	font-family: 'Arial Narrow';
	line-height: 1;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background-color: transparent !important;
	color: var(--sky-100);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	background: linear-gradient(90deg, var(--sky-light-085) 0, var(--sky-100) 8px, var(--sky-100) calc(100% - 8px), var(--sky-light-085) 100%) !important;
	color: var(--white-100) !important;
	font-size: var(--fontsize-14);
	font-weight: normal !important;
	letter-spacing: var(--spacing-x-larger);
}

.ant-form-item-label {
	padding: 0 !important;
}

/* ToolTip气泡框样式 */
.ant-tooltip {
	color: var(--white-75) !important;
	font-size: var(--fontsize-12) !important;
}

.ant-tooltip-inner {
	border-radius: 6px !important;
}

/* 帮助 提示 气泡框样式 */
.ant-tooltip.tooltipHelp,
.ant-tooltip.tooltipWarning {
	max-width: var(--wh-518) !important;
}

.ant-tooltip.tooltipWarning .anticon.anticon-exclamation-circle {
	color: var(--naked-100);
}

.ant-tooltip.tooltipWarning .anticon.anticon-exclamation-circle:after {
	top: -0.5px;
	left: -0.5px;
	border: 2px solid var(--naked-35);
}

.ant-tooltip.tooltipHelp>.ant-tooltip-content>.ant-tooltip-arrow>.ant-tooltip-arrow-content::before,
.ant-tooltip.tooltipWarning>.ant-tooltip-content>.ant-tooltip-arrow>.ant-tooltip-arrow-content::before {
	background: var(--white-100) !important;
}

.ant-tooltip.tooltipHelp>.ant-tooltip-content>.ant-tooltip-inner,
.ant-tooltip.tooltipWarning>.ant-tooltip-content>.ant-tooltip-inner {
	border-radius: 8px !important;
	background: var(--white-100);
}

.ant-tooltip.tooltipHelp>.ant-tooltip-content>.ant-tooltip-inner,
.ant-tooltip.tooltipWarning>.ant-tooltip-content>.ant-tooltip-inner {
	box-shadow: 0 3px 6px -4px var(--sky-light-385), 0 6px 16px 0 var(--sky-light-385), 0 9px 28px 8px var(--sky-light-385);
}

.ant-tooltip.tooltipHelp .ant-tooltip-arrow-content,
.ant-tooltip.tooltipWarning .ant-tooltip-arrow-content {
	box-shadow: 0 0 7px var(--sky-light-385) !important;
}

.ant-tooltip.tooltipHelp .ant-badge-status-text,
.ant-tooltip.tooltipWarning .ant-badge-status-text {
	min-width: var(--wh-78) !important;
}


/* 头像图标 */
.ant-avatar,
.ant-avatar-image {
	box-sizing: border-box;
	background: var(--sky-light-385) !important;

	color: var(--sky-55) !important;
}

.ant-avatar.ant-avatar-circle {
	border: 1px solid var(--sky-0) !important;
}

.ant-avatar.ant-avatar-circle>span.ant-avatar-string {
	font-family: 'Arial';
	font-size: var(--fontsize-12) !important;
	text-shadow: 0 0 4px var(--white-100);
}

.ant-avatar.ant-avatar-circle.ant-avatar-icon {
	display: flex !important;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	/* border: 1px solid var(--sky-light-385) !important; */
	font-size: var(--fontsize-16) !important;
}


/* POPOVER */
.ant-popover-inner {
	text-align: center;
	border-radius: 16px !important;
	border: none !important;
	box-shadow: 0 0 24px var(--sky-15) !important;
}

.ant-popover-arrow-content {
	box-shadow: 0 0 8px var(--sky-15) !important;
}

/* 年报警日历 */
.ant-popover-inner .ant-badge {
	cursor: pointer;
}

.ant-popover-inner .ant-space,
.ant-popover-inner .ant-space-item {
	width: 100%;
}

.ant-popover-inner .ant-badge .ant-typography {
	font-size: smaller !important;
	color: rgb(140 140 140 / 85%);
}

.ant-popover-inner .ant-badge:hover .ant-typography {
	color: var(--font-normal);
	transition: all .8s;
}

/* 首页F4数据源提示信息框 */
.ant-popover.F4InfoSource .ant-popover-inner {
	background: linear-gradient(90deg, var(--white-0) 0%, var(--white-100) 80px, var(--white-100) calc(100% - 80px), var(--white-0) 100%);
}

.ant-popover.F4InfoSource .ant-popover-inner .ant-popover-title {
	width: 88px;
	min-height: 26px;

	border-bottom: 1px solid var(--black-5);

	font-size: var(--fontsize-12);
	font-weight: 600;
}

.ant-popover.F4InfoSource .ant-popover-inner .ant-popover-inner-content {
	padding: 8px 16px;
	width: 100% !important;
	font-size: var(--fontsize-12);
	letter-spacing: var(--spacing-larger);
}


/* 选择器提示 */
.ant-popover-buttons {
	text-align: center !important;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.ant-popover-inner .ant-result-extra {
	display: none;
}

/* Modal弹框样式调整 */
.ant-modal {
	width: calc(100% - 20px) !important;
}

.ant-modal.ant-modal-confirm {
	max-width: 480px !important;
}

.ModalMini {
	max-width: 380px !important;
}

.ModalSmall {
	max-width: 600px !important;
}

.ModalDefault {
	max-width: 768px !important;
}

.ModalNormal {
	max-width: 980px !important;
}

.ModalLarge {
	max-width: 1200px !important;
}

.ant-modal-content {
	border-radius: 30px !important;
}

.ant-modal-content .ant-result-extra {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ant-modal-header {
	border-radius: 30px 30px 0 0 !important;
	overflow: hidden;
}

.ant-modal-header .ant-space {
	gap: 0 !important;
}

.ant-modal-header .ant-space .ant-breadcrumb {
	font-weight: 100 !important;
}

.ant-modal-header .ant-modal-title {
	font-size: var(--fontsize-16);
	font-weight: 600 !important;
}

.ant-modal-footer {
	overflow: hidden;
	display: flex;
	justify-content: flex-end;
	text-align: right;

	padding: 10px 24px !important;
	border-radius: 0 0 30px 30px !important;
	gap: 8px;

	height: 55px;
}

.ant-modal-footer .ant-btn {
	min-width: 112px !important;
}

/* 添加邮箱弹框 */
.ModalAddAlarmMail .ant-checkbox-group .ant-checkbox-wrapper span {
	color: var(--font-45);
}

.ModalAddAlarmMail .ant-checkbox-group .ant-checkbox-wrapper.ant-checkbox-wrapper-checked span {
	color: var(--font-85);
}


/* 忘记密码弹框 */
.ModalForgetPassword .ant-modal-body {
	padding: 24px 24px 12px 24px;
}

.ModalForgetPassword .ant-drawer-close {
	position: absolute;
	right: 0;
}

.ModalForgetPassword .ant-modal-footer {
	display: flex;
	justify-content: center;
}

/* 各种弹框弹框 */
.ant-modal .ant-modal-footer .ant-btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	min-width: 138px !important;
	margin: 0 !important;
}

.ModalUserCenter .ant-modal-body,
.ModalEdit .ant-modal-body,
.ModalAdd .ant-modal-body,
.ModalTrans .ant-modal-body,
.ModalAddRole .ant-modal-body,
.ModalBuildPs .ant-modal-body {
	padding: 0;
}

/* 新增授权弹框的特殊性 有背景色区分列表层次 */
.ModalAddRole .ant-modal-body {
	background: rgb(249 249 249 / 100%);
}

/* 新增授权弹框的特殊性 弹框标题居中 */
.ModalAddRole .ant-modal-header .ant-modal-title {
	justify-content: center;
	text-align: center;
}

.ModalUserCenter .ant-modal-header .ant-typography.ant-typography-son,
.ModalEdit .ant-modal-header .ant-typography.ant-typography-son,
.ModalAdd .ant-modal-header .ant-typography.ant-typography-son,
.ModalTrans .ant-modal-header .ant-typography.ant-typography-son,
.ModalAddRole .ant-modal-header .ant-typography.ant-typography-son,
.ModalBuildPs .ant-modal-header .ant-typography.ant-typography-son {
	font-size: var(--fontsize-14);
	font-weight: normal;
}

.ModalUserCenter .ant-modal-footer,
.ModalEdit .ant-modal-footer,
.ModalAdd .ant-modal-footer,
.ModalLog .ant-modal-footer {
	justify-content: flex-end;
	text-align: right;
}

/* 电站转让弹框特殊 任何状态下弹框底部按钮居中 */
.ModalTrans .ant-modal-footer,
.ModalAddRole .ant-modal-footer,
.ModalBuildPs .ant-modal-footer {
	justify-content: center;
	text-align: center;
}

.ModalUserCenter .ant-divider-horizontal.ant-divider-with-text,
.ModalEdit .ant-divider-horizontal.ant-divider-with-text,
.ModalAdd .ant-divider-horizontal.ant-divider-with-text,
.ModalTrans .ant-divider-horizontal.ant-divider-with-text,
.ModalBuildPs .ant-divider-horizontal.ant-divider-with-text {
	margin: 8px 0 !important;
}


/* 用户首页主数据设置弹框 */
/* .ModalDataSet .ant-checkbox-group .ant-checkbox-group-item {
	width: calc(33.333333% - 8px) !important;
} */

.ModalDataSet .ant-checkbox-group {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	box-sizing: border-box;
	gap: 4px;
}

.ModalDataSet .ant-checkbox-group .ant-checkbox-group-item {
	margin-right: 0;
	min-width: calc(33.333333% - 4px) !important;
}

.ModalDataSet .ant-checkbox-wrapper.checkBoxAll {
	padding: 0 0 2px 0;
	margin: 0 0 12px 0;
	border-bottom: 1px solid var(--border-default-color);
	box-shadow: 0 8px 8px -8px var(--box-shadow-color) !important;

	box-sizing: border-box;

	width: 100%;
}

.ModalDataSet .ant-checkbox-wrapper.checkBoxAll .ant-checkbox-wrapper {
	display: inline;
}

.ModalDataSet .ant-checkbox-wrapper.checkBoxAll>span {
	font-weight: 600 !important;
}

/* .ModalDataSet .ant-modal-body>.ant-form-item:last-child {
	margin: 0 !important;
} */

/* .ModalDataSet .ant-space.ant-space-vertical,
.ModalDataSet .ant-checkbox-wrapper,
.ModalDataSet .ant-checkbox-group {
	width: 100% !important;
} */

/* 报警历史数据弹框 */
.ModalAlarmInfo .ant-modal-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	overflow: visible;
}

.ModalAlarmInfo .ant-modal-body {
	min-height: var(--wh-466) !important;
}

.ModalAlarmInfo .ant-modal-close {
	display: flex;
	justify-content: center;
	align-items: center;

	height: 64px;
	top: 10px !important;
}

/* 信息提示框 */
.ant-notification-notice {
	border-radius: 16px !important;
}

.ant-notification-notice.synchronous-ing {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.ant-notification-notice.synchronous-ing .ant-notification-notice-message,
.ant-notification-notice.synchronous-ing a.ant-notification-notice-close {
	display: none !important;
}

@media (max-width:768px) {

	.ModalAddThreshold .ant-modal-footer,
	.ModalAddThreshold .ant-modal-header .ant-modal-title {
		justify-content: center;
		text-align: center;
	}
}

@media (max-width:576px) {

	.ModalUserCenter .ant-typography,
	.ModalEdit .ant-typography {
		text-align: center !important;
	}

	.ModalUserCenter .ant-modal-footer,
	.ModalEdit .ant-modal-footer,
	.ModalAdd .ant-modal-footer,
	.ModalTrans .ant-modal-footer,
	.ModalLog .ant-modal-footer,
	.ModalAddRole .ant-modal-footer,
	.ModalAddThreshold .ant-modal-footer,
	.ModalBuildPs .ant-modal-footer {
		justify-content: center;
		text-align: center;
	}

	.ModalUserCenter .ant-modal-header .ant-modal-title,
	.ModalEdit .ant-modal-header .ant-modal-title,
	.ModalAdd .ant-modal-header .ant-modal-title,
	.ModalTrans .ant-modal-header .ant-modal-title,
	.ModalLog .ant-modal-header .ant-modal-title,
	.ModalAddRole .ant-modal-header .ant-modal-title,
	.ModalAddThreshold .ant-modal-header .ant-modal-title,
	.ModalBuildPs .ant-modal-header .ant-modal-title {
		justify-content: center;
		text-align: center;
	}

	.ModalDataSet .ant-checkbox-group .ant-checkbox-group-item {
		width: calc(50% - 8px) !important;
	}
}

/* Divier通用样式 */
.ant-divider-horizontal.ant-divider-dashed {
	margin: 12px 0;
}

span.ant-divider-inner-text>.ant-divider.ant-divider-vertical {
	top: 0;
	display: inline-block;
	height: 0.9em;
	margin: 0 8px;
}

.anticon.anticon-question-circle {
	margin: 0;
}

.anticon.anticon-history {
	margin: 0;
}

.anticon.anticon-user {
	margin-top: -3px !important;
}

.anticon.anticon-link {
	font-size: smaller;
	margin-left: 2px;
	margin-right: 2px;
	opacity: .55;
}

.anticon.anticon-info-circle,
.anticon.anticon-close,
.anticon.anticon-close-circle,
.anticon.anticon-check-circle,
.anticon.anticon-question-circle,
.anticon.anticon-exclamation-circle,
.anticon.anticon-down,
.anticon.anticon-up {
	display: inline-block;
}

.ant-divider-inner-text {
	font-size: var(--fontsize-14) !important;
}

.ant-divider-inner-text .ant-space {
	gap: 4px !important;
}

.ant-divider-horizontal.ant-divider-with-text {
	margin: 8px 0 !important;
}

.ant-divider-horizontal.ant-divider-with-text .ant-btn {
	top: -1px;

	font-size: var(--fontsize-12) !important;
	color: var(--sky-35);

	border: none !important;
	border-color: transparent !important;

	box-shadow: 0 0 12px var(--sky-0);

	background: rgb(245 250 255 / 0%);
}

.ant-divider-horizontal.ant-divider-with-text .ant-btn.ant-btn-icon-only.ant-btn-sm {
	width: 18px;
	height: 18px;
	min-width: 18px;
}

.ant-divider-horizontal.ant-divider-with-text .ant-btn:hover {
	box-shadow: 0 0 12px var(--sky-75);
	color: var(--sky-100);
	background: var(--white-100);
}

/* 线条中的图标文字排版样式 */
.ant-divider-inner-text {
	display: flex !important;
	align-items: center;
	flex-wrap: nowrap !important;
}

.ant-divider-inner-text .icon-svg {
	color: rgb(95 95 95 / 55%);
	fill: rgb(95 95 95 / 55%);
	margin-right: 5px;
}

/* 引用ICONSVG组件时启用 */
/* .ant-divider .ant-typography {
	display: flex;
	flex-direction: row;
	align-items: center;
} */

.ant-divider-inner-text .ant-typography {
	font-weight: 600;
}

.ant-divider-inner-text .ant-typography:not(:first-child) {
	font-weight: 400;
}

/* 含有提示的Divider */
.ant-divider.DividerSecondary.ant-divider-with-text::before,
.ant-divider.DividerSecondary.ant-divider-with-text::after,
.ant-divider.DividerWarning.ant-divider-with-text::before,
.ant-divider.DividerWarning.ant-divider-with-text::after {
	border-top: 1px solid rgb(0 0 0 / 2.5%) !important;
	border-top-color: rgb(0 0 0 / 2.5%) !important;
}

.ant-divider-horizontal.ant-divider-with-text.DividerSecondary {
	margin: 8px 0 0 0 !important;
}

.ant-divider.DividerWarning .ant-divider-inner-text,
.ant-modal .ant-divider .ant-divider-inner-text {
	padding-left: 0;
}

.ant-divider.DividerWarning.ant-divider-with-text::before,
.ant-modal .ant-divider.ant-divider-with-text::before {
	top: 50%;
	width: 0;
	transition: var(--transition-slow);
}

.ant-divider.DividerWarning.ant-divider-with-text::after,
.ant-modal .ant-divider.ant-divider-with-text::after {
	top: 50%;
	width: 100%;
	transition: var(--transition-slow);
}

.ant-divider.DividerWarning .ant-divider-inner-text {
	font-size: var(--fontsize-14) !important;
	padding-left: 0;
}

.ant-divider.DividerWarning.ant-divider-with-text::before {
	top: 50%;
	width: 0;
}

.ant-divider.DividerWarning.ant-divider-with-text::after {
	top: 50%;
	width: 100%;
}

/* 全状态标题居中 */
.ant-modal.DividerCenter .ant-modal-body,
.ant-modal.DividerCenter .ant-modal-footer {
	text-align: center !important;
	transition: var(--transition-slow);
}

.DividerCenter .ant-divider-inner-text {
	display: flex;
	justify-content: center !important;
	flex-wrap: wrap !important;
	padding-left: 16px !important;
}

.DividerCenter .ant-divider.ant-divider-vertical {
	display: none;
}

.DividerCenter .ant-divider.ant-divider-with-text::before,
.DividerCenter .ant-divider.ant-divider-with-text::after {
	top: 50% !important;
	width: 50% !important;
}

@media (max-width:768px) {
	.DividerCenterMD .ant-divider.DividerWarning .ant-typography {
		padding: 0 !important;
	}

	.DividerCenterMD .ant-divider.DividerWarning .ant-divider-inner-text {
		display: flex;
		justify-content: center !important;
		flex-wrap: wrap !important;
		padding-left: 16px !important;
	}

	.DividerCenterMD .ant-divider.DividerWarning .ant-divider.ant-divider-vertical,
	.DividerCenterMD .ant-divider.DividerWarning .ant-divider.DividerWithWarning {
		display: none;
	}

	.DividerCenterMD .ant-divider.DividerWarning.ant-divider-with-text::before,
	.DividerCenterMD .ant-divider.DividerWarning.ant-divider-with-text::after {
		top: 50%;
		width: 50%;
	}
}

@media (max-width:576px) {

	.ant-modal.DividerCenterSM .ant-modal-body,
	.ant-modal.DividerCenterSM .ant-modal-footer {
		text-align: center !important;
		transition: var(--transition-slow);
	}

	.ant-modal .ant-typography {
		padding: 0 !important;
	}

	.DividerCenterSM .ant-divider-inner-text {
		display: flex;
		justify-content: center !important;
		flex-wrap: wrap !important;
		padding-left: 16px !important;
	}

	.DividerCenterSM .ant-divider.DividerWarning .ant-divider.ant-divider-vertical,
	.DividerCenterSM .ant-divider.DividerWarning .ant-divider.DividerWithWarning {
		display: none;
	}

	.DividerCenterSM .ant-divider.ant-divider-with-text::before,
	.DividerCenterSM .ant-divider.ant-divider-with-text::after {
		top: 50% !important;
		width: 50% !important;
	}
}

/* 警告语图标动画 */
.anticon.anticon-question-circle,
.anticon.anticon-info-circle,
.anticon.anticon-exclamation-circle,
.anticon.anticon-close-circle {
	position: relative;
	width: 14px;
	height: 14px;
	border-radius: 50%;
}

.ant-form-item-feedback-icon .anticon.anticon-close-circle {
	position: relative;
	width: 12px;
	height: 12px;
	border-radius: 50%;
}

.anticon.anticon-question-circle:after,
.anticon.anticon-info-circle:after,
.anticon.anticon-exclamation-circle:after,
.anticon.anticon-close-circle:after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 50%;
	animation: antStatusProcessing 1.2s infinite ease-in-out;
	animation-iteration-count: none;
	content: '';
}

.ant-typography .anticon.anticon-question-circle:after,
.ant-typography .anticon.anticon-info-circle:after {
	border: 2px solid rgb(0 0 0 / 15%);
}

.ant-typography-success .anticon.anticon-info-circle:after {
	border: 2px solid rgb(82 196 26 / 25%) !important;
}

.ant-typography-warning .anticon.anticon-info-circle:after,
.ant-typography .anticon.anticon-exclamation-circle:after {
	border: 2px solid rgb(250 173 20 / 35%);
}

.ant-typography-danger .anticon.anticon-exclamation-circle:after,
.ant-typography .anticon.anticon-close-circle:after,
.ant-form-item-feedback-icon .anticon.anticon-close-circle:after {
	border: 2px solid rgb(255 77 79 / 35%);
}

/* 步骤条样式调整 */
.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
	font-size: var(--fontsize-14);
}

.ant-steps-item-container {
	display: inline;
	justify-content: center;
	align-items: center;
}

.ant-steps-item-process>.ant-steps-item-container .icon-svg,
.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
	color: var(--sky-100) !important;
	fill: var(--sky-100) !important;
	transition: var(--transition-faster);
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
	font-weight: 600 !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
	background-color: rgb(239 247 255 / 100%) !important;
	transition: var(--transition-faster);
}

.ant-steps-item-finish>.ant-steps-item-container .icon-svg,
.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
	color: rgb(123 192 255 / 100%) !important;
	fill: rgb(123 192 255 / 100%) !important;
	transition: var(--transition-faster);
}

.ant-steps-item-wait>.ant-steps-item-container .icon-svg,
.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
	color: var(--font-ccc) !important;
	fill: var(--font-ccc) !important;
	transition: var(--transition-normal);
}

/* 骨架屏动态效果 */
.ant-skeleton-element .ant-skeleton-button {
	background: rgb(249 249 249 / 100%) !important;
	border-radius: 6px !important;
}

.ant-skeleton-active .ant-skeleton-button::after {
	background: linear-gradient(90deg, rgb(254 254 254 / 100%) 25%, rgb(249 249 249 / 100%) 57%, rgb(254 254 254 / 100%) 63%) !important;
}

/* 报警颜色 */
.ant-typography.ant-typography-warning {
	color: var(--naked-100) !important;
}

.ant-tabs-nav-operations {
	display: none !important;
}

@media (max-width: 768px) {
	.ant-tabs-top>.ant-tabs-nav::before {
		border-bottom: 1px solid var(--white-0) !important;
	}

	.ant-tabs-nav-list {
		display: flex;
		flex-direction: column;
	}

	.ant-tabs-tab {
		letter-spacing: 2.8px;
		padding: 4px 0 4px 8px !important;
		margin: 0 !important;
		border-left: 2px solid rgb(240 240 240 / 100%) !important;
		transition: var(--transition-fast);
	}

	.ant-tabs-tab.ant-tabs-tab-active {
		border-left: 2px solid var(--sky-100) !important;
	}

	.ant-tabs-ink-bar {
		display: none;
	}
}

/* 抽屉 */
/* 设备管理-同步帮助抽屉 */
.ant-drawer.syncHelp>.ant-drawer-content-wrapper {
	width: 468px !important;
}

/* 报警历史-阈值告警详情抽屉 */
.ant-drawer.DrawerThresholdDetail {
	border-radius: 30px;
	overflow: hidden;
}

.ant-drawer.DrawerThresholdDetail .ant-drawer-mask {
	position: absolute;
	border-radius: 30px;
	background: var(--black-5) !important;
}

.ant-drawer.DrawerThresholdDetail>.ant-drawer-content-wrapper {
	border-radius: 30px;
	width: 68% !important;
}

.ant-drawer.DrawerThresholdDetail>.ant-drawer-content-wrapper>.ant-drawer-content {
	border-radius: 30px;
	background: transparent;
}

.ant-drawer.DrawerThresholdDetail .ant-drawer-body {
	border-radius: 30px;
	padding: 0;
	padding: 24px;
	box-sizing: border-box;
	background: var(--white-100) !important;
}

@media (max-width: 576px) {
	.ant-drawer.DrawerThresholdDetail>.ant-drawer-content-wrapper {
		width: calc(100% - 48px) !important;
		background: var(--white-100) !important;
	}

	.ant-drawer.DrawerThresholdDetail .ant-drawer-body {
		padding: 12px;
	}
}

/* 滚动条样式 */
/* 定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸 */
::-webkit-scrollbar {
	width: 17px;
	height: 17px;
	transition: var(--transition-x-slower) !important;
}

/* 定义滑块 内阴影+圆角 */
::-webkit-scrollbar-thumb {
	border: 7px solid var(--white-95);
	border-radius: 17px;
	box-sizing: border-box;
	background: var(--sky-35);
	-webkit-box-shadow: 0 0 3px var(--white-0);

	transition: var(--transition-x-slower) !important;
}

::-webkit-scrollbar-thumb:hover {
	border: 6px solid var(--white-100);
	background: var(--sky-85);
}

/* 定义滚动条轨道 内阴影+圆角 */
::-webkit-scrollbar-track {
	border-radius: 0;
	background: var(--white-0);
	-webkit-box-shadow: 0;
}


/* 漫游导航组件样式 */
.react-joyride__spotlight {
	border-radius: 6px !important;
	box-shadow: 0 0 12px var(--white-15) !important;
}

.react-joyride__tooltip {
	border-radius: 12px !important;
	color: var(--font-85) !important;
	font-size: var(--fontsize-14) !important;
	font-weight: lighter !important;
}

.react-joyride__tooltip>div:last-child {
	display: flex;
	flex-direction: row;
	justify-content: center !important;
	align-items: center !important;
	text-align: center !important;
	margin: 0 !important;
	gap: 4px;

	width: 100% !important;
}

.react-joyride__tooltip>div:last-child>div:first-child {
	position: absolute;
	z-index: -999;
}

.react-joyride__tooltip>div>button,
.react-joyride__tooltip>div>button,
.react-joyride__tooltip>div>div>button,
.react-joyride__tooltip>div>div>button::before,
.react-joyride__tooltip>button>svg>g>path {
	transition: var(--transition-normal);
}

.react-joyride__tooltip>div>button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;

	margin: 0 !important;
	padding: 0 8px;
	box-sizing: border-box;

	border-radius: 4px !important;

	background: var(--sky-100) !important;
	box-shadow: none !important;

	color: var(--white-100) !important;
	font-size: var(--fontsize-12) !important;
	letter-spacing: var(--spacing-large) !important;

	width: calc(var(--wh-98) - var(--spacing-large)) !important;
	height: var(--wh-24);
}

.react-joyride__tooltip>div>button:hover {
	background: var(--sky-btnhover) !important;
}

.__floater {
	padding: 8px 0 0 !important;
}

/* 左边栏菜单的相关调用组件图标调整 */
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
	width: 5px !important;
	height: 0.5px !important;
	border-radius: 0px !important;
}

.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
	right: 14px !important;
}

/* 限宽样式 */
span.limitingWidth {
	display: block !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
	overflow: hidden !important;
	width: fit-content;
}

span.limitingWidth.maxWidth264 {
	max-width: 264px;
}

.ant-result {
	padding: 48px 0 !important;
}

.ant-result-title {
	font-size: var(--fontsize-18);
	letter-spacing: var(--spacing-small);
}

/* 结果反馈后的外链交互样式 GoNow */
.ant-space.hoverSwitch {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	height: 24px;
	overflow: hidden;
}

.ant-space.hoverSwitch .ant-btn {
	min-width: var(--wh-108);
}

.ant-space.hoverSwitch>.ant-space-item:first-child {
	transform: var(--size-normal);
	transition: var(--transition-normal);
}

.ant-space.hoverSwitch>.ant-space-item:first-child .ant-typography {
	cursor: default;
}

.ant-space.hoverSwitch:hover>.ant-space-item:first-child {
	padding: 0 !important;
	margin: 0 !important;
	border: none !important;
	height: 0 !important;
	min-height: 0 !important;
	max-height: 0 !important;
	transform: var(--size-none);
	opacity: 0 !important;
}

.ant-space.hoverSwitch>.ant-space-item:last-child {
	padding: 0 !important;
	margin: 0 !important;
	border: none !important;
	height: 0 !important;
	min-height: 0 !important;
	max-height: 0 !important;
	transform: var(--size-none);
	transition: var(--transition-normal);
	opacity: 0 !important;
}

.ant-space.hoverSwitch:hover>.ant-space-item:last-child {
	height: fit-content !important;
	min-height: fit-content !important;
	max-height: fit-content !important;
	transform: var(--size-normal);
	opacity: 1 !important;
}