html.semi-dark .sidebar-wrapper {
	background: var(--black-85);
	border-right: 1px solid var(--black-10);
	box-shadow: 0 0.1rem 0.7rem var(--black-light-385) !important;
}

html.semi-dark .sidebar-wrapper .sidebar-header {
	background: var(--black-85);
	border-right: 1px solid var(--black-10);
	border-bottom: 1px solid var(--white-15)
}

html.semi-dark .sidebar-wrapper .sidebar-header .logo-text {
	color: #e7e9ec
}

html.semi-dark .sidebar-wrapper .sidebar-header .toggle-icon {
	color: #e7e9ec
}

html.semi-dark .sidebar-wrapper .metismenu a {
	color: #9ea4aa
}

html.semi-dark .sidebar-wrapper .metismenu .mm-active>a,
html.semi-dark .sidebar-wrapper .metismenu a:active,
html.semi-dark .sidebar-wrapper .metismenu a:focus,
html.semi-dark .sidebar-wrapper .metismenu a:hover {
	color: var(--white-100);
	text-decoration: none;
	background: var(--white-15)
}

html.semi-dark .sidebar-wrapper .metismenu ul {
	border: 1px solid var(--white-100);
	background: var(--white-885)
}

html.semi-dark .simplebar-scrollbar:before {
	background: var(--white-045)
}

html.semi-dark .logo-icon {
	filter: invert(1) grayscale(100%) brightness(200%)
}

html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-1,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-10,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-11,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-12,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-2,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-3,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-4,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-5,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-6,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-7,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-8,
html.ColorLessIcons .sidebar-wrapper .metismenu .icon-color-9 {
	color: inherit
}

html.minimal-theme .topbar {
	background: var(--background-keynote);
	border-bottom: 1px solid var(--border-keynote);
	box-shadow: none !important
}

html.minimal-theme .sidebar-wrapper .metismenu ul {
	background: var(--background-keynote);
	border-bottom: 1px solid var(--border-keynote);
	box-shadow: none !important
}

html.minimal-theme .topbar .search-control {
	background-color: var(--background-keynote);
	border: 1px solid var(--border-keynote)
}

html.minimal-theme .topbar .user-box {
	border-left: 1px solid var(--border-keynote)
}

html.minimal-theme .sidebar-wrapper {
	background: var(--background-keynote);
	border-right: 1px solid var(--border-keynote);
	box-shadow: none !important
}

html.minimal-theme .sidebar-header {
	background: var(--background-keynote);
	border-bottom: 1px solid var(--border-keynote);
	background-clip: padding-box
}

html.minimal-theme .card {
	background-color: var(--background-keynote);
	box-shadow: none;
	border: 1px solid var(--black-15) !important;
}

html.minimal-theme .page-footer {
	background: var(--background-keynote);
}

@media (min-width:1025px) {
	html.minimal-theme .wrapper.toggled.sidebar-hovered .sidebar-wrapper {
		box-shadow: 0 .3rem .8rem var(--black-15) !important
	}
}