.icon-svg {
    font-size: var(--fontsize-16);
    transform: scale(1);
    overflow: hidden;

    transition: var(--transition-normal);
}

.icon-svg,
.icon-svg div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    height: 100%;
}

.icon-svg svg {
    height: 1em;
}

/* Size */
.xx-smaller {
    transform: scale(0.5);
}

.x-smaller {
    transform: scale(0.6);
}

.smaller {
    transform: scale(0.7);
}

.small {
    transform: scale(0.8);
}

.normal {
    transform: scale(1);
}

.large {
    transform: scale(1.1);
}

.larger {
    transform: scale(1.2);
}

.wh-14 svg {
    width: 14px;
    height: 14px;
}